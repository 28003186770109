import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Modal from '../components/Modal'

import logo from '../images/logo-1.jpg'
import videoMp4 from '../images/Squat-it.mp4'
import videoWebm from '../images/Squat-it.webm'
import eddiePhoto from '../images/20190526_073833.jpg'
import robinaPhoto from '../images/32986.jpeg'
import shelbiPhoto from '../images/shelbi.jpeg'
import daveyPhoto from '../images/Davey.jpg'
import lisaPhoto from '../images/Lisa.jpg'

import { IoIosMail } from 'react-icons/io'
import { MdDirections } from 'react-icons/md'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Modal />
    <div className="home-grid">
      <section className="hero-section">
        <div className="bg-video">
          <video className="bg-video__content" autoPlay muted loop>
            <source src={videoMp4} type="video/mp4" />
            <source src={videoWebm} type="video/webm" />
            Your browser is not supported!
          </video>
        </div>
        <h1 className="heading-primary">Midtown Trainers</h1>
      </section>

      <section className="about-section" id="about">
        <h2 className="heading-secondary">About Us</h2>
        <div className="about shadow mb-2">
          <h3 className="heading-tertiary">What We Do</h3>
          <h5>Personal Training</h5>
          <p>One-on-one private training</p>
          <h5>Small Group Training</h5>
          <p>
            Share your time, energy, motivation and goals with like-minded
            people
          </p>
          <h5>Custom Memberships</h5>
          <p>Self-guided fitness training provided by a Midtown trainer</p>
        </div>
        <div className="about shadow mb-2 pt-2">
          <h3 className="heading-tertiary">How It Works</h3>
          <p>
            Before your first workout, each new member must meet with a trainer
            for an assessment and personalized workout program. Why? Our goal is
            for you to get the most out of your time in the gym. We want you to
            know exactly what to do and why you are doing it. You can be
            reassessed each month or wait longer if you'd like. Your assessment
            and plan is $60 and is paid to the trainer that performs this for
            you.
          </p>
        </div>
        <a className="btn-black" href="#contact">
          Get Started
        </a>
      </section>

      <section className="trainers-section" id="trainers">
        <h2 className="heading-secondary d-flex justify-content-center mb-3">
          The Trainers
        </h2>

        <div className="grid">
          <div className="card">
            <img src={eddiePhoto} alt="Eddie Henry" className="card-img" />
            <h3 className="card-heading">Eddie Henry</h3>
            <p className="card-text">Owner/Trainer</p>
          </div>

          <div className="card">
            <img src={logo} alt="Chris Blount" className="card-img" />
            <h3 className="card-heading">Chris Blount</h3>
            <p className="card-text">Owner/Trainer</p>
          </div>

          <div className="card">
            <img src={logo} alt="Bethany Blount" className="card-img" />
            <h3 className="card-heading">Bethany Blount</h3>
            <p className="card-text">Trainer</p>
          </div>

          <div className="card">
            <img src={daveyPhoto} alt="Davey Jones" className="card-img" />
            <h3 className="card-heading">Davey Jones</h3>
            <p className="card-text">Trainer</p>
          </div>

          <div className="card">
            <img src={robinaPhoto} alt="Robina McDaniel" className="card-img" />
            <h3 className="card-heading">Robina McDaniel</h3>
            <p className="card-text">Trainer</p>
          </div>

          <div className="card">
            <img src={logo} alt="Julie Shamburger" className="card-img" />
            <h3 className="card-heading">Julie Shamburger</h3>
            <p className="card-text">Trainer</p>
          </div>

          <div className="card">
            <img src={lisaPhoto} alt="Lisa Kennedy" className="card-img" />
            <h3 className="card-heading">Lisa Kennedy</h3>
            <p className="card-text">Trainer</p>
          </div>

          <div className="card">
            <img src={logo} alt="Sydney Tebo" className="card-img" />
            <h3 className="card-heading">Sydney Tebo</h3>
            <p className="card-text">Trainer</p>
          </div>

          <div className="card">
            <img src={shelbiPhoto} alt="Shelbi Rowe" className="card-img" />
            <h3 className="card-heading">Shelbi Rowe</h3>
            <p className="card-text">Trainer</p>
          </div>
        </div>
      </section>

      <section className="pricing-section" id="pricing">
        <h3 className="heading-secondary">Pricing</h3>

        <div className="memberships">
          <div className="memberships-individual shadow mb-2">
            <h3 className="heading-tertiary">Custom Memberships</h3>
            <div className="plans">
              <div className="plans-year">
                <h6>Yearly Plans</h6>
                <ul>
                  <li>Single Member - $400/YR (SAVES $80) </li>
                  <li>2 Family Members - $600/YR (SAVES $120)</li>
                  <li>3 Family Members - $700/YR (SAVES $140)</li>
                </ul>
              </div>

              <div className="plans-month">
                <h6>Monthly Plans</h6>
                <ul>
                  <li>Single Member - $40/Month</li>
                  <li>+ 2nd Person - $20/Month</li>
                  <li>Each Additional - $10/Month</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="memberships-corporate shadow mb-2">
            <h3 className="heading-tertiary">Custom Corporate Memberships</h3>
            <ul>
              <li>Minimum of 4 Members</li>
              <li>$20 Per Member</li>
            </ul>
          </div>
        </div>
        <p className="year-deal">Pay 1 Year Upfront And Get 2 Months Free!</p>
      </section>

      <section className="contact-section" id="contact">
        <div className="sign-up">
          <div className="sign-up__form">
            <h4 className="heading-secondary mb-2">Contact</h4>
            <div className="card">
              <address>
                <div className="card-icon">
                  <IoIosMail size={32} />
                </div>
                <a className="mb-1" href="mailto:getfitatmidtown@gmail.com">
                  getfitatmidtown@gmail.com
                </a>
                {/* <div className="card-icon">
                  <FaPhone size={24} />
                </div>
                <a className="mb-1" href="tel:+12519784411">
                  (251) 978-4411
                </a> */}
                <div className="card-icon">
                  <MdDirections size={32} />
                </div>
                <p className="card-text">
                  1550 A1 West 2nd St. Gulf Shores, Alabama 36542
                </p>
              </address>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default IndexPage
