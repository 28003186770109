import React, { useState } from 'react'
import styled from 'styled-components'

const PopUpStyles = styled.div`
  .pop-up {
    background-color: #000;
    color: #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    margin-top: 1rem;
    position: fixed;
    top: 0;
    left: 5%;
    z-index: 10;

    height: 95vh;
    width: 90%;
    border-radius: 5px;

    transition: all 0.3s ease-in;
    transform-origin: center;
  }

  .nav-btn {
    padding: 0.5rem 0.5rem;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      path {
        fill: #888;
      }
    }
  }

  .nav-svg {
    height: 1.75rem;
    width: 1.75rem;

    path {
      transition: all 0.3s ease-in;
      fill: #ddd;
    }
  }

  .show {
    transform: scale(1);
    opacity: 1;
    z-index: 10;
  }

  .hide {
    transform: scale(0.95);
    opacity: 0;
    z-index: -1;
  }
`

const H2 = styled.h2`
  background-image: linear-gradient(to top right, #0be881, #4bcffa);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`

const Modal = () => {
  const [isOpen, setOpen] = useState(true)

  function toggle() {
    setOpen(!isOpen)
  }

  function toggleKeyPress() {
    setOpen(!isOpen)
  }

  return (
    <PopUpStyles>
      <div className={`pop-up ${isOpen ? 'show' : 'hide'}`}>
        <H2>Black Friday Special!</H2>
        <p>Get 4 Months Free on a Yearly Membership!</p>
        <p>That's $320 for a Year! That's $160 off our normal annual rate!</p>
        <p>
          This offer is good from Wednesday November 25th until Friday November
          27th at 3PM CST!
        </p>
        <p>
          Contact us at{' '}
          <a href="mailto:getfitatmidtown@gmail.com">
            getfitatmidtown@gmail.com
          </a>{' '}
          to get started!
        </p>
        <button
          onClick={toggle}
          onKeyPress={toggleKeyPress}
          type="button"
          className="nav-close nav-btn"
        >
          <svg className="nav-svg" viewBox="0 0 24 24">
            <path
              fillRule="evenodd"
              d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
            />
          </svg>
        </button>
      </div>
    </PopUpStyles>
  )
}

export default Modal
